<template functional>
  <div>
    <!-- If the product has a warehousePromo with a type of 1, 2 or 5, show promo markup -->
    <div
      v-if="
        !props.cartItem &&
          props.product.warehousePromo &&
          (props.product.warehousePromo.warehousePromoTypeId === 1 ||
            props.product.warehousePromo.warehousePromoTypeId === 2 ||
            props.product.warehousePromo.warehousePromoTypeId === 5)
      "
      class="product-price"
      :class="props.product.warehousePromo.promoType"
    >
      <!-- Markup for warehousePromo types 1 and 2 -->
      <div
        v-if="
          props.product.warehousePromo.warehousePromoTypeId === 1 ||
            props.product.warehousePromo.warehousePromoTypeId === 2
        "
        class="d-flex flex-column w-100"
      >
        <div class="d-flex align-center">
          <span class="mr-1">
            {{ props.product.warehousePromo.view.MPieces }}
          </span>
          <span class="mr-2">{{ props.product.priceUnitDisplay }}</span>
          <span
            v-html="props.product.warehousePromo.view.price"
            class="font-weight-bold"
          ></span>
        </div>
        <div class="d-flex align-center">
          <!-- Left side -->
          <div class="price__wrapper__left">
            <div v-html="props.product.warehousePromo.view.mxn"></div>
          </div>
          <!-- Right side -->
          <div class="price__wrapper__right">
            <span
              v-html="props.product.warehousePromo.view.curPrice"
              class="current-price d-block font-weight-bold"
            ></span>
            <small class="unit-price text-right">
              {{ parent.$t("product.price.aPiece") }}
            </small>
          </div>
        </div>
        <!-- Footer information -->
        <div class="price__wrapper__footer">
          <span>{{ parent.$t("product.price.instead") }}</span>
          <span
            class="ml-2"
            v-html="props.product.warehousePromo.view.price"
          ></span>
          <span v-if="props.product.priceUnitDisplay">
            /{{ props.product.priceUnitDisplay }}
          </span>
        </div>
      </div>
      <!-- Markup for warehousePromo type 5 (discount) -->
      <div
        v-else-if="props.product.warehousePromo.warehousePromoTypeId === 5"
        class="w-100 d-flex flex-column"
      >
        <div class="d-flex align-center">
          <!-- Left side -->
          <div class="price__wrapper__left">
            <span v-html="props.product.warehousePromo.view.header"></span>
          </div>
          <!-- Right side -->
          <div class="price__wrapper__right">
            <span class="current-price">
              {{ parent.$n(props.product.priceDisplay, "currency") }}
            </span>
            <small class="unit-price text-right">
              {{
                parent.$n(
                  $options.confezione(
                    props.product.productInfos.TIPOLOGIA,
                    props.product.priceDisplay,
                    props.product.productInfos.WEIGHT_SELLING
                  ),
                  "currency"
                )
              }}
            </small>
            <small
              v-if="props.product.productInfos.TIPOLOGIA === 'Sfuso'"
              class="unit-price text-right"
            >
              /{{ props.product.weightUnitDisplay }}
            </small>
          </div>
        </div>
        <div
          v-if="props.product.priceStandardDisplay"
          class="price__wrapper__footer d-flex align-center"
        >
          <span class="text-decoration-line-through">
            {{ parent.$n(props.product.priceStandardDisplay, "currency") }}
          </span>
        </div>
      </div>
    </div>

    <!-- Default markup if no warehousePromo or if its type is not 1, 2 or 5 -->
    <div v-else class="product-price">
      <div
        class="old-price"
        v-if="
          props.product.priceStandardDisplay &&
            props.showStandardPrice &&
            props.product.priceStandardDisplay > 0.01
        "
      >
        {{
          parent.$n(
            $options.confezione(
              props.product.productInfos.TIPOLOGIA,
              props.product.priceStandardDisplay,
              props.product.productInfos.WEIGHT_SELLING
            ),
            "currency"
          )
        }}
        <span
          v-if="props.product.productInfos.TIPOLOGIA === 'Sfuso'"
          class="unit-price"
        >
          /{{ props.product.weightUnitDisplay }}
        </span>
      </div>
      <div class="d-flex justify-space-between align-end price-container">
        <div class="cur-price">
          {{
            parent.$n(
              $options.confezione(
                props.product.productInfos.TIPOLOGIA,
                props.product.priceDisplay,
                props.product.productInfos.WEIGHT_SELLING
              ),
              "currency"
            )
          }}
          <span
            v-if="props.product.productInfos.TIPOLOGIA === 'Sfuso'"
            class="unit-price"
          >
            /{{ props.product.weightUnitDisplay }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
/** Global rules */
:root {
  --promo-red: #c13733;
  --promo-blue-mxn: #54798c;
  --promo-green-mxn: #8ca665;

  --promo-yellow: #f8d849;
  --promo-blue: #324c95;

  --promo-per-blue: #283666;
  --promo-per-gold: #af8a51;

  --promo-bf-green: #00933a;

  --promo-hero-red: #ad342e;

  --promo-sotto-red: #eb101a;
  --promo-sotto-yellow: #f2d025;

  --promo-sigle-digit: #0077b2;

  --promo-one-one-green: #d1d265;
  --promo-one-one-red: rgb(145, 36, 38);
  --promo-one-one-red-one: rgba(145, 36, 38, 1);
  --promo-one-one-red-two: rgba(176, 45, 44, 1);
  --promo-one-one-red-three: rgba(213, 55, 48, 1);

  --promo-pop-yellow: #ffe538;
}
.pp-wrapper {
  width: fit-content;
}
// FOR NON PROMO
.product-price {
  width: fit-content;
  max-width: fit-content;
  .current-price {
    text-align: right;
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 0;
    white-space: nowrap;
    &.not-in-promo {
      font: normal normal 600 24px/26px Rubik, arial, sans-serif;
    }
  }
}
// For PROMO
.product-price {
  .price__wrapper__left,
  .price__wrapper__right {
    display: flex;
    min-height: 65px;
    height: 65px;
    min-width: 70px;
    width: 50%;
    padding: 5px;
  }
  .price__wrapper__left {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    .header {
      display: flex;
      flex-direction: column;
    }
    .header-body {
      font-size: 1.5rem;
    }
  }
  .price__wrapper__right {
    flex-direction: column;
    justify-content: center;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
  .price__wrapper__footer {
    display: flex;
    align-items: center;
  }
}

/** 2x1 */
.product-price.promo_2x1 {
  width: 100%;
  .price__wrapper__left {
    color: var(--promo-yellow);
    background-color: var(--promo-red);
    > div {
      font-size: 1.4rem;
    }
  }
  .price__wrapper__right {
    background-color: var(--promo-green-mxn);

    color: var(--promo-yellow);
  }
  .price__wrapper__footer {
    justify-content: flex-start;
  }
}
/** 1+1 */
.product-price.promo_1x1 {
  width: 100%;
  .price__wrapper__left {
    color: #fff;
    background: var(--promo-one-one-red);
    background: linear-gradient(
      90deg,
      var(--promo-one-one-red-one) 0%,
      var(--promo-one-one-red-two) 50%,
      var(--promo-one-one-red-three) 100%
    );
    > div {
      font-size: 1.4rem;
    }
    .promo-one-by-one {
      position: relative;
      width: 60px;
      height: 50px;
      .mxn-number {
        position: absolute;
        z-index: 1;
        top: 6px;
        left: 6px;
        font-style: italic;
        font-size: 1.8rem;
        color: #000;
        -webkit-text-stroke: 1.8px #ffffff;
        text-stroke: 1.8px #ffffff;
        line-height: 1;
        font-weight: bold;
        letter-spacing: 0px;
      }
      .discount-text {
        text-transform: uppercase;
        transform: skewX(-10deg);
        z-index: 0;
        font-size: 12px;
        background-color: #3a393a;
        padding: 2px 5px;
        color: #ffffff;
        max-width: fit-content;
        width: fit-content;
        position: absolute;
        bottom: 4px;
        right: 0px;
      }
    }
  }
  .price__wrapper__right {
    background-color: var(--promo-one-one-green);
    color: #000;
  }
  .price__wrapper__footer {
    justify-content: flex-start;
  }
}
/** mxn */
.product-price.promo_mxn {
  width: 100%;
  .price__wrapper__left {
    color: var(--promo-yellow);
    background-color: var(--promo-red);
    > div {
      font-size: 1.4rem;
    }
  }
  .price__wrapper__right {
    background-color: var(--promo-blue-mxn);

    color: var(--promo-yellow);
  }
  .price__wrapper__footer {
    justify-content: flex-start;
  }
}
/** Sconto */

/** Global and 40_su_40 */
.product-price {
  .price__wrapper__left {
    background-color: var(--promo-yellow);
    color: var(--promo-red);
  }
  .price__wrapper__right {
    background-color: var(--promo-red);
    color: #ffffff;
  }
  &.C_40SU40 {
    .price__wrapper__footer {
      justify-content: flex-start;
      span {
        margin-left: 40px;
      }
    }
  }
}
.product-price.C_40_SCONTO,
.product-price.BLACK_FRIDAY {
  .price__wrapper__footer {
    justify-content: flex-end;
  }
}
/** 40% */
.product-price.C_40_SCONTO {
  .price__wrapper__left {
    background-color: var(--promo-per-gold);
    color: #ffffff;
  }
  .price__wrapper__right {
    background-color: var(--promo-per-blue);
    color: #ffffff;
  }
}
/** Black friday */
.product-price.BLACK_FRIDAY {
  .price__wrapper__left {
    background-color: var(--promo-bf-green);
    color: #ffffff;
  }
  .price__wrapper__right {
    background-color: #000000;
    color: #ffffff;
  }
}
/* HEROES */
.product-price.SUPERHERO {
  .price__wrapper__left {
    background-color: var(--promo-hero-red);
    color: #ffffff;
  }
  .price__wrapper__right {
    background-color: #000000;
    color: #ffffff;
  }
}
/** BRIVIDO  */
.product-price.BRIVIDO {
  .price__wrapper__left {
    background-color: var(--promo-yellow);
    color: var(--promo-red);
  }
  .price__wrapper__right {
    background-color: var(--promo-per-blue);
    color: #ffffff;
  }
}
/** SOTTO */
.product-price.SOTTO {
  .price__wrapper__left {
    background-color: var(--promo-sotto-red);
    color: var(--promo-sotto-yellow);
  }
  .price__wrapper__right {
    background-color: var(--promo-sotto-yellow);
    color: #000;
  }
}
/** Eur. 1,2,3 */
.product-price.EURO1,
.product-price.EURO2,
.product-price.EURO3 {
  .price__wrapper__left {
    background-color: transparent;
    color: var(--promo-sigle-digit);
  }
  .price__wrapper__right {
    background-color: transparent;
    color: var(--default-base);
  }
}
.product-price.PREZZI_POP {
  .price__wrapper__left {
    background-color: #000;
    color: #ffffff;
    .header-header {
      position: relative;
      font-weight: normal !important;
      &::before {
        position: absolute;
        content: "";
        left: 48%;
        top: 50%;
        right: 0;
        border-top: 2px solid;
        border-color: inherit;
        -webkit-transform: rotate(-15deg);
        transform: rotate(-15deg);
        width: 50px;
        -webkit-transform: translate(-50%, -50%) rotate(-15deg);
        transform: translate(-50%, -50%) rotate(-24deg);
        width: 55px;
      }
    }
  }
  .price__wrapper__right {
    background-color: var(--promo-pop-yellow);

    color: #000000;
  }
  .price__wrapper__footer {
    display: none !important;
  }
}
</style>
<script>
import toNumber from "lodash/toNumber";
export default {
  name: "props.productPrice",
  props: {
    leafletProduct: { type: Boolean, required: false, default: false },
    product: { type: Object, required: true },
    cartItem: { type: Boolean, required: false, default: false },
    showStandardPrice: { type: Boolean, default: true }
  },
  confezione(tiplogia, price, weightUnit) {
    if (tiplogia == "Confezione") {
      return (price * 1000) / (1000 / toNumber(weightUnit, 1)) / 1000;
    } else {
      return price;
    }
  }
};
</script>
